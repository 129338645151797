<template>
  <div
    class="m-infobanner"
    :class="{ 'm-infobanner_button': isButton }"
  >
    <div class="m-infobanner__title">
      <p>
        {{ Math.trunc(num) }}<span v-if="!isButton">{{ getFloatPart() }}</span>
      </p>

      <p>{{ isButton ? (`${shopsDecl + declCity}`) : $t("molecules.infobanner.mark") }}</p>
    </div>
    <div
      v-if="isButton"
      class="m-infobanner__info"
    >
      <ul>
        <li>{{ $t("molecules.infobanner.best") }}</li>
        <li>{{ $t("molecules.infobanner.price") }}</li>
      </ul>
      <AButton
        v-if="!hideBtn"
        variant="grey"
        size="lg"
        @click="$emit('open:shop')"
      >
        {{ $t("molecules.infobanner.map") }}
      </AButton>
    </div>
    <div
      v-else
      class="m-infobanner__info"
    >
      <p>{{ $t("molecules.infobanner.reviews") }}</p>
      <AImage
        src="/assets/images/logo.svg"
        provider="public"
        width="154"
        height="28"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { declOfNum } from '@/utils/string'
import { useLocalization } from '#imports'

import AButton from '@/components/atoms/Button/AButton.vue'
import AImage from '@/components/atoms/Image/AImage.vue'

defineEmits(['open:shop'])
const props = defineProps({
  isButton: {
    type: Boolean,
    default: false
  },
  num: {
    type: Number,
    required: true
  },
  city: {
    type: String,
    required: false,
    default: ''
  },
  hideBtn: Boolean
})
const { t } = useLocalization()
const getFloatPart = (): string => {
  const floatPart = (props.num % 1).toFixed(2).slice(1)
  return floatPart[2] === '0' ? floatPart.slice(0, 2) : floatPart
}

const shopsDecl = declOfNum(props.num, [
  t('molecules.infobanner.declination.shops.nominative'),
  t('molecules.infobanner.declination.shops.genitive'),
  t('molecules.infobanner.declination.shops.plural')
])

const dictionary = new Map([
  ['Брянск', 'Брянске'],
  ['Великий Новгород', 'Великом Новгороде'],
  ['Владимир', 'Владимире'],
  ['Волгоград', 'Волгограде'],
  ['Вологда', 'Вологде'],
  ['Воронеж', 'Воронеже'],
  ['Екатеринбург', 'Екатеринбурге'],
  ['Краснодар', 'Краснодаре'],
  ['Москва', 'Москве'],
  ['Мурманск', 'Мурманске'],
  ['Нижний Новгород', 'Нижнем Новгороде'],
  ['Новороссийск', 'Новороссийске'],
  ['Новосибирск', 'Новосибирске'],
  ['Новый Уренгой', 'Новом Уренгое'],
  ['Ростов-на-Дону', 'Ростове-на-Дону'],
  ['Самара', 'Самаре'],
  ['Санкт-Петербург', 'Санкт-Петербуре'],
  ['Тверь', 'Твери'],
  ['Тула', 'Туле'],
  ['Тюмень', 'Тюмени'],
  ['Уфа', 'Уфе'],
  ['Ярославль', 'Ярославле']
])

const declCity = computed(() => dictionary.get(props.city) ?? props.city)
</script>

<style lang="postcss">
.m-infobanner {
  --infobanner-title-width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 21rem;
  height: 27.5rem;
  padding: var(--spacer-4xl) 0 var(--spacer-xl) var(--spacer-xl);
  border-radius: var(--border-radius-xl);
  background-color: var(--color-yellow-800);
  background-image: url("/assets/images/infobanner-bg-desktop-yellow.png");
  background-position: top left;
  background-size: cover;

  &.m-infobanner_button {
    --infobanner-title-width: 9.25rem;

    height: 100%;
    min-height: 29.25rem;
    background-color: var(--color-red-400);
    background-image: url("/assets/images/infobanner-bg-desktop.png");
    background-position: top right;
  }

  @media (--screen-lg) {
    &__title {
      padding-right: var(--spacer-xs);
    }

    &__info {
      padding-right: var(--spacer-xl);
    }
  }
}

.m-infobanner__title > p:first-child {
  color: var(--color-white);
  font-weight: 800;
  font-size: 8.5rem;
  line-height: 100%;

  & > span {
    font-size: 5rem;
    line-height: 100%;
  }

  & + p {
    display: inline;
    max-width: var(--infobanner-title-width);
    color: var(--color-white);
    white-space: pre-line;

    @mixin text-4xl;
  }
}

.m-infobanner__info {
  & > ul {
    margin-bottom: var(--spacer-base);

    & > li {
      display: flex;
      align-items: center;
      width: max-content;

      &::before {
        content: "";
        display: inline-block;
        width: 0.25rem;
        height: 0.25rem;
        margin: 0 0.625rem 0 var(--spacer-5xs);
        border-radius: var(--border-radius-full);
        background-color: var(--color-white);
      }
    }
  }

  & > .button {
    width: 100%;
  }

  & > p {
    margin-bottom: var(--spacer-4xs);
  }

  & > .image img {
    object-fit: contain;
    width: auto;
    height: 2rem;
    margin-right: auto;
    filter: brightness(0) invert(1);
  }

  & > p,
  & > ul > li {
    color: var(--color-white);

    @mixin text-base-semibold;
  }
}

@media (--screen-xs) {
  .m-infobanner {
    width: 11.75rem;
    height: 16.25rem;
    padding: var(--spacer-base) var(--spacer-xs) var(--spacer-xs);
    border-radius: var(--border-radius-base);
    background-image: url("/assets/images/infobanner-bg-mobile-yellow.png");

    &.m-infobanner_button {
      min-height: 16.25rem;
      background-image: url("/assets/images/infobanner-bg-mobile.png");
    }
  }

  .m-infobanner__title > p:first-child {
    font-size: 5.5rem;

    & > span {
      font-size: 3.25rem;
    }

    & + p {
      max-width: 100%;

      @mixin text-base-bold;
    }
  }

  .m-infobanner__info {
    & > ul {
      display: none;
    }

    & > .button {
      height: 2rem;

      @mixin text-sm-bold;
    }

    & > p {
      overflow: hidden;
      height: 1.25rem;
      margin-bottom: 0;

      @mixin text-sm;
    }

    & > .image img {
      height: 1.75rem;
    }
  }
}
</style>
